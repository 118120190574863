import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { windowActions } from "./windowSlice";
import React from "react";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useOutsideClick = (callback: any) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref?.current && !(ref?.current as any)?.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref, callback]);

  return ref;
};

export const useScreenSizeListener = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        windowActions.updateScreenSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      );
    }, 500);
    return () => clearInterval(interval);
  }, [dispatch]);

  React.useEffect(() => {
    const handleResize = () => {
      dispatch(
        windowActions.updateScreenSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      );
    };

    window.addEventListener("resize", handleResize);

    handleResize();
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
};

export const useScreenInfos = () => {
  const window = useAppSelector((x) => x.window);
  const isSmallScreen = window.width && window.width < 500;
  const headerBackgroundBreakPoint = window.width && window.width > 900;

  return {
    headerBackgroundBreakPoint: headerBackgroundBreakPoint,
    isSmallScreen: isSmallScreen,
    windowWidth: window.width,
    windowHeight: window.height,
  };
};
