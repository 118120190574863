import React from "react";
import "./App.css";
import { Grid } from "@mui/material";
import secondBackground from "./Assets/SecondBackground.png";
import BackgroundHandKeyboard from "./Assets/BackgroundHandKeyboard.png";
import headerBackground from "./Assets/header_background.png";
import headerBackgroundLarge from "./Assets/header_background_large.png";
import { useScreenInfos, useScreenSizeListener } from "./Redux/hooks";
import { Navbar, RoundedButtonForm } from "./Navbar";
import emailjs from "emailjs-com";
import GradientBackgroundButton from "./GradientBackgroundButton/GradientBackgroundButton";
import AfricaKeyboardImage from "./Assets/AfricaKeyboard.png";
import { ReactComponent as ItererIcon } from "./Assets/ItererIcon.svg";
import { ReactComponent as TesterIcon } from "./Assets/TesterIcon.svg";
import { ReactComponent as ComprendreIcon } from "./Assets/ComprendreIcon.svg";
import { ReactComponent as AccompagnerIcon } from "./Assets/AccompagnerIcon.svg";
import DigigabFullLogoColor from "./Assets/digigab_full_logo_color.png";
import BulleBigImage from "./Assets/bullesBig.png";
import MyFlipCard from "./Card";

export const FlipCardService: React.FC<{
  title: string;
  icon: JSX.Element;
  firstDescription: string;
  secondDescription: string;
}> = (props) => {
  return (
    <Grid item display="grid">
      <MyFlipCard
        verso={
          <Grid
            container
            direction="column"
            wrap="nowrap"
            gap="20px"
            padding="20px"
          >
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Nunito Sans",
                  fontSize: myFontSize.small,
                  fontWeight: 400,
                  color: "white",
                  textAlign: "left",
                }}
              >
                {props.firstDescription}
              </span>
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Nunito Sans",
                  fontSize: myFontSize.small,
                  fontWeight: 700,
                  color: "white",
                  textAlign: "left",
                }}
              >
                {props.secondDescription}
              </span>
            </Grid>
          </Grid>
        }
        recto={
          <Grid
            container
            direction="column"
            wrap="nowrap"
            alignItems={"center"}
            gap="20px"
            padding="20px"
          >
            <Grid item display="grid">
              {props.icon}
            </Grid>
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Nunito Sans",
                  fontWeight: "bold",
                  fontSize: myFontSize["medium+"],
                  color: "white",
                  whiteSpace: "nowrap",
                }}
              >
                {props.title}
              </span>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export const BarProgress: React.FC<{ isFull: boolean; isMobile?: boolean }> = (
  props
) => {
  return (
    <div
      style={{
        width: props.isMobile ? "7px" : "15px",
        height: "5px",
        background: props.isFull
          ? "linear-gradient(176deg, #7A4092 0%, #144391 45.83%, #7CCCE2 100%)"
          : "#C5C9CE",
      }}
    ></div>
  );
};

export const CheckedSVGProgress: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM16.8364 8.3636C17.1878 8.71507 17.1878 9.28493 16.8364 9.63636L10.8364 15.6364C10.4849 15.9878 9.91512 15.9878 9.5636 15.6364L7.1636 13.2364C6.81214 12.8849 6.81214 12.3151 7.1636 11.9636C7.51507 11.6122 8.08493 11.6122 8.4364 11.9636L10.2 13.7272L12.8818 11.0454L15.5636 8.3636C15.9151 8.01214 16.4849 8.01214 16.8364 8.3636Z"
        fill="url(#paint0_linear_213_585)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_213_585"
          x1="17.6"
          y1="-2.4"
          x2="9.2"
          y2="22.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1875" stop-color="#7A4092" />
          <stop offset="0.614583" stop-color="#144391" />
          <stop offset="0.963542" stop-color="#7CCCE2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const NotCheckedSVGProgress: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM16.8364 8.3636C17.1878 8.71507 17.1878 9.28493 16.8364 9.63636L10.8364 15.6364C10.4849 15.9878 9.91512 15.9878 9.5636 15.6364L7.1636 13.2364C6.81214 12.8849 6.81214 12.3151 7.1636 11.9636C7.51507 11.6122 8.08493 11.6122 8.4364 11.9636L10.2 13.7272L12.8818 11.0454L15.5636 8.3636C15.9151 8.01214 16.4849 8.01214 16.8364 8.3636Z"
        fill="#C5C9CE"
      />
    </svg>
  );
};

export const myFontSize = {
  small: "min(max(12px, 0.92vw), 14px)",
  medium: "min(max(13px, 1.055vw), 16px)",
  "medium+": "min(max(16px, 1.297vw), 20px)",
  "medium++": "min(max(20px, 2vw), 30px)",
  large: "min(max(25px, 3.1vw), 45px)",
};

export const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const Presentation: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap={"40px"}
      maxWidth={"1000px"}
      padding="0px 40px 0px 40px"
    >
      <Grid item display="grid">
        <span
          style={{
            fontSize: myFontSize.large,
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "Nunito Sans",
          }}
        >
          {`Conception Web et Automatisation`}
        </span>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontSize: myFontSize.medium,
            color: "white",
            fontWeight: "normal",
            textAlign: "center",
            fontFamily: "Montserrat",
          }}
        >
          {`DigiGab Technologies Solutions crée des sites web performants et automatise vos processus métiers avec des solutions no-code.`}
        </span>
      </Grid>
      <Grid item display="grid" alignSelf="center">
        <GradientBackgroundButton
          style={{
            fontSize: myFontSize.medium,
          }}
          text="On en parle ?"
          onClick={() => {
            scrollToElement("container-formulaire");
          }}
        />
      </Grid>
    </Grid>
  );
};

const HeaderApp: React.FC = () => {
  const screenInfos = useScreenInfos();

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap={"20px"}
      paddingBottom={screenInfos.headerBackgroundBreakPoint ? "140px" : "120px"}
      style={{
        backgroundImage: `url(${
          screenInfos.headerBackgroundBreakPoint
            ? headerBackgroundLarge
            : headerBackground
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <Grid item display="grid">
        <Navbar />
      </Grid>
      <Grid item display="grid" alignSelf={"center"}>
        <Presentation />
      </Grid>
    </Grid>
  );
};

const SectionDescriptionDigiGab: React.FC = () => {
  const screenInfos = useScreenInfos();
  const isBackground =
    screenInfos.windowWidth && screenInfos.windowWidth > 1150;

  return (
    <Grid
      container
      direction="row"
      style={
        isBackground
          ? {
              backgroundImage: `url(${BulleBigImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center right",
            }
          : {}
      }
      gap="30px"
      paddingLeft="50px"
      paddingRight="50px"
      paddingBottom="50px"
      justifyContent={"space-around"}
    >
      <Grid item display="grid">
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap="40px"
          paddingTop={
            screenInfos.windowWidth &&
            screenInfos.windowWidth > 780 &&
            screenInfos.windowWidth < 900
              ? "50px"
              : "0px"
          }
        >
          <Grid item display="grid">
            <span
              style={{
                fontFamily: "Nunito Sans",
                fontWeight: "bold",
                fontSize: myFontSize.large,
                color: "#031636",
                maxWidth: "830px",
              }}
            >
              {`Une expertise axée sur la conception de sites web et l’automatisation de tâches via des outils no-code.`}
            </span>
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                whiteSpace: "pre-line",
                fontFamily: "Montserrat",

                fontSize: myFontSize.medium,
                color: "#031636",
                maxWidth: "800px",
              }}
            >
              {`Nous savons que chaque entreprise est unique et mérite une approche personnalisée.\n\nAvant de commencer la conception de votre projet, nous entamons une phase de découverte détaillée. Cette phase initiale est essentielle pour comprendre votre identité, explorer la culture de votre entreprise et analyser en profondeur vos activités. Nous nous concentrons sur l'identification de vos atouts, de vos objectifs clés et des points d'amélioration nécessaires.\n\nNotre objectif est de vous fournir des solutions sur mesure, parfaitement adaptées à vos besoins. Nous utilisons notre méthodologie CITA (Comprendre, Itérer, Tester, Accompagner), qui repose sur une compréhension approfondie, des itérations régulières, des tests rigoureux et un accompagnement continu.\n\nEn incorporant des technologies no-code, nous automatisons vos processus pour optimiser votre efficacité opérationnelle et réduire vos coûts. Cette approche nous permet d’intégrer des solutions qui non seulement répondent à vos besoins actuels mais anticipent également vos futurs défis.`}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ServiceItem: React.FC<{
  serviceName: string;
  filledIcon: any;
  notFilledIcon: any;
}> = (props) => {
  const [isHover, setIsHover] = React.useState<boolean>(false);
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap="15px"
      alignItems={"center"}
    >
      <Grid item display="grid">
        <div
          className="service-item"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isHover ? (
            React.isValidElement(props.filledIcon) ? (
              props.filledIcon
            ) : (
              <props.filledIcon />
            )
          ) : React.isValidElement(props.notFilledIcon) ? (
            props.notFilledIcon
          ) : (
            <props.notFilledIcon />
          )}
        </div>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Nunito Sans",
            fontWeight: "bold",
            fontSize: myFontSize.medium,
            color: "white",
            whiteSpace: "nowrap",
          }}
        >
          {props.serviceName}
        </span>
      </Grid>
    </Grid>
  );
};

const SectionNosServices: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap="30px"
      style={{
        backgroundColor: "#031636",
      }}
      paddingLeft="50px"
      paddingRight="50px"
      paddingTop="35px"
      paddingBottom="50px"
      alignItems={"center"}
    >
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Nunito Sans",
            fontSize: myFontSize.large,
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
            color: "white",
          }}
        >{`Nos services`}</span>
      </Grid>

      <Grid item display="grid">
        <Grid
          container
          direction="row"
          gap="40px"
          justifyContent={"space-evenly"}
          maxWidth="1300px"
        >
          <Grid item display="grid">
            <FlipCardService
              firstDescription="Nous concevons des solutions digitales qui répondent réellement à vos enjeux."
              secondDescription="MVP, Marketplace, messagerie interne, dashboard, CRM..."
              icon={
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M64.0861 18.7391C60.7632 19.7461 54.9967 21.3825 48.8169 22.6565C47.3936 14.0048 44.8984 5.37572 43.6982 1.46802C52.6244 4.19317 59.9827 10.5123 64.0861 18.7391Z"
                    fill="white"
                  />
                  <path
                    d="M43.8309 23.5784C40.3329 24.1436 36.8745 24.5129 33.8112 24.5129C30.7479 24.5129 27.2896 24.1436 23.7915 23.5784C25.132 15.3921 27.5249 7.02946 28.7482 3.03739C29.0615 2.01574 29.1858 1.61445 29.4016 1.07585C29.5091 0.806781 29.6186 0.559991 29.7722 0.238495C31.0967 0.0808382 32.4443 -0.000244141 33.8112 -0.000244141C35.2387 -0.000244141 36.6459 0.0882445 38.027 0.260038C38.0566 0.37213 38.0837 0.472436 38.1104 0.567333C38.1893 0.847708 38.3133 1.24175 38.5213 1.90297L38.5432 1.97243C39.5865 5.28792 42.3503 14.5354 43.8309 23.5784Z"
                    fill="white"
                  />
                  <path
                    d="M18.8054 22.6565C20.2225 14.0429 22.7012 5.46028 23.8992 1.55123L23.9247 1.46802C14.9984 4.19314 7.64021 10.5123 3.53662 18.7391C6.85926 19.7461 12.6257 21.3825 18.8054 22.6565Z"
                    fill="white"
                  />
                  <path
                    d="M33.8111 49.8711C36.6348 49.8711 39.7325 49.5884 42.8767 49.1382C41.5445 55.5224 39.8427 61.4232 38.8737 64.5841C38.5607 65.6059 38.4361 66.0071 38.2207 66.5458C38.1131 66.815 38.0033 67.0617 37.8497 67.3834C36.5256 67.5408 35.1777 67.622 33.8111 67.622C32.4441 67.622 31.0965 67.5408 29.7721 67.3834C29.6185 67.0617 29.509 66.815 29.4014 66.5458C29.186 66.0074 29.0614 65.6059 28.7481 64.5844C27.7795 61.4236 26.0777 55.523 24.7456 49.1395C27.8793 49.588 30.9774 49.8711 33.8111 49.8711Z"
                    fill="white"
                  />
                  <path
                    d="M11.3939 46.453C7.20785 45.4113 3.64261 44.365 1.46777 43.6975C4.74141 54.4205 13.2014 62.8804 23.9244 66.1539L23.899 66.0709C22.8528 62.6575 20.8303 55.6812 19.3907 48.2321C16.5912 47.6908 13.8693 47.0686 11.3939 46.453Z"
                    fill="white"
                  />
                  <path
                    d="M66.0705 43.7229C62.6568 44.7692 55.6801 46.7917 48.2307 48.2311C46.7913 55.6805 44.7684 62.6572 43.7225 66.0709L43.6968 66.1539C54.4198 62.8804 62.88 54.4205 66.1535 43.6975L66.0705 43.7229Z"
                    fill="white"
                  />
                  <path
                    d="M1.61598 23.4542C0.566676 26.7184 0 30.1991 0 33.8118C0 35.2254 0.0867175 36.6184 0.255129 37.986L0.787994 38.1637L0.792245 38.1653L0.797461 38.167L0.801132 38.1682C0.972636 38.2249 5.99923 39.8849 12.6189 41.532C14.4868 41.9966 16.4647 42.4561 18.4836 42.877C18.0334 39.7332 17.7509 36.6355 17.7509 33.8118C17.7509 31.8761 17.8837 29.8117 18.1122 27.6916C11.3808 26.3231 5.1914 24.5447 1.88689 23.5393L1.8639 23.5323L1.61598 23.4542Z"
                    fill="white"
                  />
                  <path
                    d="M0.354492 38.0723C0.471543 38.0874 0.566956 38.1035 0.598609 38.1106C0.626334 38.1177 0.669774 38.1289 0.68552 38.1334L0.720329 38.1434L0.739071 38.1489L0.758778 38.155L0.775297 38.1602C0.766023 38.1573 0.578161 38.1103 0.354492 38.0723Z"
                    fill="white"
                  />
                  <path
                    d="M49.51 27.6917C49.7389 29.8122 49.8716 31.8769 49.8716 33.8119C49.8716 36.6359 49.5892 39.7337 49.1387 42.8775C55.5229 41.5453 61.4241 39.8435 64.5849 38.8749C65.6063 38.5615 66.0079 38.4369 66.5463 38.2215C66.8155 38.1139 67.0622 38.0045 67.3838 37.8509C67.5413 36.5264 67.6225 35.1788 67.6225 33.8119C67.6225 30.1992 67.0557 26.7185 66.0066 23.4543L65.7332 23.5404C62.4281 24.5459 56.24 26.3236 49.51 27.6917Z"
                    fill="white"
                  />
                  <path
                    d="M44.5097 28.605C44.6946 30.4169 44.8005 32.1706 44.8005 33.8119C44.8005 36.8842 44.4292 40.3536 43.8612 43.8612C40.3535 44.4289 36.8842 44.8005 33.8119 44.8005C30.7534 44.8005 27.2851 44.4302 23.7623 43.86C23.1946 40.3526 22.8232 36.8839 22.8232 33.8119C22.8232 32.1713 22.9292 30.4173 23.1143 28.605C26.8116 29.1968 30.5 29.5855 33.8119 29.5855C37.1241 29.5855 40.8124 29.1968 44.5097 28.605Z"
                    fill="white"
                  />
                </svg>
              }
              title="Application web"
            />
          </Grid>
          <Grid item display="grid">
            <FlipCardService
              firstDescription="Nous vous accompagnons dans la mise en place de vos objectifs digitaux pour penser, améliorer et booster votre visibilité numérique."
              secondDescription="Création de landing page, Ecommerce, Site vitrine, site d'entreprise, refonte de site web.."
              icon={
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10V57.6224C0 63.1452 4.47715 67.6224 10 67.6224H65.2362C66.5544 67.6224 67.6229 66.5538 67.6229 65.2357V10C67.6229 4.47716 63.1458 0 57.6229 0H10ZM62.8496 52.8489C62.8496 58.3718 58.3724 62.8489 52.8496 62.8489H14.7734C9.25052 62.8489 4.77336 58.3718 4.77336 52.8489V24.7164C4.77336 19.1936 9.25051 14.7164 14.7734 14.7164H52.8496C58.3724 14.7164 62.8496 19.1936 62.8496 24.7164V52.8489Z"
                    fill="white"
                  />
                  <path
                    d="M50.8924 22.1877H16.7292C13.2015 22.1877 10.3418 25.0475 10.3418 28.5751C10.3418 32.1028 13.2015 34.9625 16.7292 34.9625H50.8924C54.42 34.9625 57.2798 32.1028 57.2798 28.5751C57.2798 25.0475 54.42 22.1877 50.8924 22.1877Z"
                    fill="white"
                  />
                  <path
                    d="M49.4236 40.9294H46.8388C42.4999 40.9294 38.9824 44.4469 38.9824 48.7859C38.9824 53.1248 42.4999 56.6423 46.8388 56.6423H49.4236C53.7625 56.6423 57.28 53.1248 57.28 48.7859C57.28 44.4469 53.7625 40.9294 49.4236 40.9294Z"
                    fill="white"
                  />
                  <path
                    d="M30.6281 41.167H11.5351C10.8761 41.167 10.3418 41.7013 10.3418 42.3603C10.3418 43.0193 10.8761 43.5536 11.5351 43.5536H30.6281C31.2872 43.5536 31.8215 43.0193 31.8215 42.3603C31.8215 41.7013 31.2872 41.167 30.6281 41.167Z"
                    fill="white"
                  />
                  <path
                    d="M30.6281 54.0178H11.5352C10.8761 54.0178 10.3418 54.5521 10.3418 55.2112C10.3418 55.8703 10.8761 56.4046 11.5352 56.4046H30.6281C31.2872 56.4046 31.8215 55.8703 31.8215 55.2112C31.8215 54.5521 31.2872 54.0178 30.6281 54.0178Z"
                    fill="white"
                  />
                  <path
                    d="M30.6281 47.5931H11.5352C10.8761 47.5931 10.3418 48.1274 10.3418 48.7865C10.3418 49.4456 10.8761 49.9799 11.5352 49.9799H30.6281C31.2872 49.9799 31.8215 49.4456 31.8215 48.7865C31.8215 48.1274 31.2872 47.5931 30.6281 47.5931Z"
                    fill="white"
                  />
                </svg>
              }
              title="Site internet"
            />
          </Grid>
          <Grid item display="grid">
            <FlipCardService
              firstDescription="Nous plaçons l'utilisateur au centre de la réflexion afin d'imaginer des interfaces optimisées pour une expérience de navigation intuitive, engageante et émotionnellement forte."
              secondDescription="User flow, Wireframe fonctionnel, prototype interactif, design system, maquette UI..."
              icon={
                <svg
                  width="77"
                  height="68"
                  viewBox="0 0 77 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6654 41.4594H39.1396L36.8061 39.2977C36.0662 38.6061 35.6279 37.6301 35.608 36.6074C35.608 36.5652 35.6179 36.5254 35.6179 36.4844H13.1563V4.97523H61.2475V36.4844H55.8372L60.45 41.4595H63.7353C65.1077 41.4595 66.224 40.3462 66.224 38.9713V2.48699C66.224 1.1132 65.1077 0 63.7353 0H10.6654C9.2932 0 8.17969 1.11336 8.17969 2.48699V38.9713C8.17969 40.346 9.2932 41.4594 10.6654 41.4594Z"
                    fill="white"
                  />
                  <path
                    d="M70.4381 52.2397H70.6727C72.7322 52.2397 74.4034 50.5696 74.4034 48.5084C74.4034 46.4475 72.7322 44.7773 70.6727 44.7773H63.5244L70.4381 52.2397Z"
                    fill="white"
                  />
                  <path
                    d="M3.7315 44.7769C1.66917 44.7769 0 46.447 0 48.508C0 50.5693 1.66917 52.2394 3.7315 52.2394H50.7762L42.7215 44.7769H3.7315Z"
                    fill="white"
                  />
                  <path
                    d="M30.4494 16.5184C30.3918 16.5184 30.3052 16.5757 30.1756 16.7066C27.7865 19.0977 26.0231 27.012 31.9114 31.894C33.2461 33.001 34.8603 33.6014 36.4397 33.6014C37.9365 33.6014 39.4035 33.0598 40.5696 31.894C43.8947 28.5689 43.4258 25.043 40.5696 23.2349C39.7052 22.6868 38.8357 22.5374 37.9891 22.5374C36.9653 22.5374 35.9663 22.7555 35.0247 22.7555C34.4568 22.7555 33.914 22.6769 33.3961 22.4293C29.403 20.5299 30.9199 16.5184 30.4494 16.5184Z"
                    fill="white"
                  />
                  <path
                    d="M75.3422 61.1936L47.2473 30.9559C46.7378 30.4076 46.0272 30.0902 45.279 30.0762C44.5306 30.0622 43.8076 30.3523 43.2784 30.8815C42.0762 32.0837 40.8736 33.2856 39.6711 34.4874C39.1419 35.0163 38.8505 35.7376 38.8637 36.4856C38.8769 37.2337 39.1941 37.9438 39.7412 38.4541L69.9347 66.6012C70.6447 67.2589 71.5661 67.6225 72.5351 67.6225C73.5567 67.6225 74.5178 67.2241 75.2408 66.4991C76.6994 65.0396 76.7445 62.7084 75.3422 61.1936ZM72.9472 64.5131C72.5132 64.9275 71.8316 64.9321 71.3918 64.5238L44.5388 39.5853L46.2976 37.8256L46.5643 37.5592L48.3253 35.7984L73.2545 62.6739C73.6762 63.1286 73.6547 63.8376 73.2062 64.2659L72.9472 64.5131Z"
                    fill="white"
                  />
                </svg>
              }
              title="UX & UI design"
            />
          </Grid>
          <Grid item display="grid">
            <FlipCardService
              firstDescription="Automatisez vos processus métier, réduisez vos coûts et gagnez du temps"
              secondDescription="Productivité, simplification, flexibilité, minimisation des erreurs humaines."
              icon={
                <svg
                  width="68"
                  height="67"
                  viewBox="0 0 68 67"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.50014 48.1562V58.625H19.1251L50.4618 27.7212L39.8368 17.2525L8.50014 48.1562ZM64.0618 51.255L52.0201 63.1195L37.2868 48.6029L42.3018 43.6616L45.1351 46.4533L52.1335 39.53L56.1568 43.4941L52.0201 47.4583L55.0235 50.25L59.0468 46.3416L64.0618 51.255ZM18.7285 30.2337L3.93848 15.745L15.9801 3.88037L20.9668 8.82162L13.9685 15.745L17.0001 18.7041L23.9701 11.7808L27.9935 15.745L23.9701 19.6812L26.8035 22.4729L18.7285 30.2337ZM58.6785 19.5416C59.7835 18.4529 59.7835 16.75 58.6785 15.6054L52.0485 9.18454C51.0001 8.09579 49.1585 8.09579 48.0535 9.18454L42.8401 14.2933L53.4651 24.762L58.6785 19.5416Z"
                    fill="white"
                  />
                </svg>
              }
              title="Automatisations"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SectionTexteAvantMethode: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{
        backgroundImage: `url(${secondBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <Grid item display="grid" alignSelf="center">
        <Grid
          container
          paddingLeft="50px"
          paddingRight="50px"
          paddingTop="35px"
          paddingBottom="50px"
          direction={"column"}
          wrap="nowrap"
          gap="40px"
        >
          <Grid item display="grid">
            <span
              style={{
                whiteSpace: "pre-line",
                color: "white",
                fontSize: myFontSize.large,
                fontFamily: "Nunito Sans",
                fontWeight: "bold",
                textAlign: "left",
                maxWidth: "820px",
              }}
            >
              {`La majorité des projets digitaux échouent.\nEnsemble, faisons de votre projet un succès.`}
            </span>
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                whiteSpace: "pre-line",
                color: "white",
                fontFamily: "Montserrat",

                fontSize: myFontSize.medium,
                maxWidth: "860px",
              }}
            >
              {`Notre approche est avant tout pragmatique et portée par les valeurs humaines intrinsèques à chaque projet. Nous croyons que chaque entreprise est unique et mérite une attention particulière pour répondre à ses besoins spécifiques et à ceux de ses utilisateurs.\n\nChez DigiGab Technologies Solutions, nous collaborons étroitement avec vous pour concevoir des sites web et des applications qui non seulement répondent parfaitement aux attentes de vos utilisateurs, mais aussi reflètent fidèlement l'identité et les valeurs de votre entreprise.\n\nEn outre, nous proposons des services d'automatisation de processus, conçus pour optimiser vos workflows et réduire vos coûts opérationnels. En intégrant des outils no-code avancés, nous pouvons automatiser vos tâches répétitives et chronophages, libérant ainsi votre équipe pour se concentrer sur des activités à plus forte valeur ajoutée. \n\nLes entreprises africaines font face à des défis uniques, et nous croyons fermement que l'adoption de technologies no-code peut transformer ces défis en opportunités. Notre objectif est de fournir des solutions innovantes et accessibles qui permettent aux entreprises africaines de se déployer rapidement, d'améliorer leur compétitivité et de réduire leurs coûts opérationnels.\n\nNotre méthodologie nécessite une implication active de votre part dans le processus de conception. Nos cycles de livraison courts permettent de recueillir rapidement vos retours et d'apporter les ajustements nécessaires en temps réel. Cette approche itérative garantit que le projet progresse de manière fluide et efficace, avec des résultats tangibles à chaque étape.`}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MethodCard: React.FC<{
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: string;
  description: string;
}> = (props) => {
  const [isHover, setIsHover] = React.useState<boolean>(false);
  const a = isHover ? "1" : "0.4";
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap="20px"
      borderRadius={"16px"}
      padding="15px 20px"
      justifyContent={"flex-start"}
      width="250px"
      height="250px"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{
        cursor: "pointer",
        backgroundImage: `linear-gradient(to bottom left, rgba(122, 64, 146, ${a}), rgba(20, 67, 145, ${a}), rgba(131, 205, 221, ${a}))`,
      }}
    >
      <Grid item display="grid">
        <props.icon />
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Nunito Sans",
            whiteSpace: "nowrap",
            fontSize: myFontSize.medium,
            color: "white",
            fontWeight: "bold",
          }}
        >
          {props.title}
        </span>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            fontFamily: "Montserrat",
            whiteSpace: "pre-line",
            fontSize: myFontSize.small,
            color: "white",
            textAlign: "left",
          }}
        >
          {props.description}
        </span>
      </Grid>
    </Grid>
  );
};

const SectionMethode: React.FC = () => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{
        backgroundColor: "#031636",
      }}
    >
      <Grid item display="grid" alignSelf="center">
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap="50px"
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            paddingTop: "35px",
            paddingBottom: "50px",
          }}
        >
          {/* First part */}
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" gap="20px">
              <Grid item display="grid">
                <span
                  style={{
                    color: "white",
                    fontSize: myFontSize.large,
                    fontFamily: "Nunito Sans",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >{`Notre méthode en 4 étapes:`}</span>
              </Grid>
              <Grid item display="grid">
                <Grid
                  container
                  direction="row"
                  gap="20px"
                  justifyContent={"space-evenly"}
                  maxWidth={"1100px"}
                >
                  <Grid item display="grid">
                    <MethodCard
                      icon={ComprendreIcon}
                      title="Comprendre"
                      description="Le cadrage de votre projet repose avant tout sur la compréhension de vos problématiques métiers et des enjeux clés de votre activité."
                    />
                  </Grid>
                  <Grid item display="grid">
                    <MethodCard
                      icon={ItererIcon}
                      title="Itérer"
                      description="Nous développons une approche iterative en respectant votre budget dans une vision d'amélioration progressive."
                    />
                  </Grid>
                  <Grid item display="grid">
                    <MethodCard
                      icon={TesterIcon}
                      title="Tester"
                      description="Nous expérimentons et validons des hypothèses pour adapter vos objectifs à vos priorités."
                    />
                  </Grid>
                  <Grid item display="grid">
                    <MethodCard
                      icon={AccompagnerIcon}
                      title="Accompagner"
                      description="Parce que votre réussite est le curseur de notre engagement nous mettons notre expertise au service de votre projet en fonction de vos besoins d’accompagnement."
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Second part */}
          <Grid item display="grid">
            <Grid container direction="row" gap="50px" alignItems="center">
              {/* Text left part */}
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="20px">
                  <Grid item display="grid">
                    <span
                      id="qui-sommes-nous-section"
                      style={{
                        color: "white",
                        fontSize: myFontSize.large,
                        fontFamily: "Nunito Sans",
                        fontWeight: "bold",
                        textAlign: "left",
                        maxWidth: "590px",
                      }}
                    >{`Parlons un peu de DigiGab, l'agence web No-code centrée sur l'Afrique.`}</span>
                  </Grid>
                  <Grid item display="grid">
                    <span
                      style={{
                        whiteSpace: "pre-line",
                        color: "white",
                        fontFamily: "Montserrat",

                        fontSize: myFontSize.medium,
                        maxWidth: "650px",
                      }}
                    >{`DigiGab Technologies Solutions est l’agence web nouvelle génération qui exploite la puissance révolutionnaire du no-code pour concevoir des produits numériques de pointe, économiques et performants. \n\nNous croyons fermement que le succès d’un projet digital repose sur une stratégie optimisée et une approche réactive. Nous collaborons étroitement avec nos clients pour comprendre leurs besoins, leurs défis et leurs objectifs. Cette collaboration nous permet de développer des solutions sur mesure qui maximisent l'efficacité et la rentabilité.\n\nNotre objectif est clair : aider les entreprises gabonaises et africaines de toutes tailles, ainsi que les entrepreneurs, à gagner du temps et à réduire les coûts de développement de leurs projets numériques. \n\nEn plus de la conception web, nous offrons des services d'automatisation marketing et de processus métier. En intégrant des outils no-code avancés, nous pouvons automatiser vos campagnes marketing et optimiser vos workflows opérationnels. \n\nVotre temps est précieux et chaque minute compte. Ne gaspillez plus des heures sur des projets coûteux et inefficaces. Avec DigiGab Technologies Solutions, bénéficiez de notre expertise en no-code pour obtenir des résultats rapides et impressionnants. \n\nRejoignez la révolution digitale avec DigiGab Technologies Solutions. Ensemble, nous pouvons propulser votre entreprise vers de nouveaux sommets et réaliser votre vision de manière tangible.`}</span>
                  </Grid>
                </Grid>
              </Grid>
              {/* Africa picture */}
              <Grid item xs display="grid">
                <img
                  src={AfricaKeyboardImage}
                  alt="Africa"
                  style={{
                    width: "270px",
                    justifySelf: 'center',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Footer: React.FC = () => {
  const screenInfos = useScreenInfos();
  const reduceGap = screenInfos.windowWidth && screenInfos.windowWidth < 1000;
  const isColumn = screenInfos.windowWidth && screenInfos.windowWidth < 800;
  return (
    <Grid
      container
      direction={isColumn ? "column-reverse" : "row"}
      rowGap={"35px"}
      columnGap={reduceGap ? "60px" : "100px"}
      padding={reduceGap ? "30px 30px" : "50px 50px"}
      justifyContent={"space-evenly"}
      wrap={isColumn ? "nowrap" : "wrap"}
    >
      {/* Logo */}
      <Grid item display="grid">
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap="20px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <img
              src={DigigabFullLogoColor}
              alt="Logo"
              style={{
                width: "120px",
              }}
            />
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                color: "#031636",
                fontSize: myFontSize.small,
                fontFamily: "Montserrat",
                whiteSpace: "nowrap",
              }}
            >{`© DigiGab Technologies Solutions 2024`}</span>
          </Grid>
        </Grid>
      </Grid>
      {/* Menu */}
      <Grid item display="grid">
        <Grid container direction="column" gap="10px" wrap="nowrap">
          <Grid item display="grid">
            <span
              style={{
                fontSize: myFontSize.medium,
                fontWeight: "bold",
                whiteSpace: "nowrap",
                fontFamily: "Nunito Sans",
              }}
            >{`MENU`}</span>
          </Grid>
          <Grid item display="grid">
            <span
              onClick={() => {
                scrollToElement("services-section");
              }}
              style={{
                cursor: "pointer",
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`Expertise`}</span>
          </Grid>
          <Grid item display="grid">
            <span
              onClick={() => {
                scrollToElement("methode-section");
              }}
              style={{
                cursor: "pointer",
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`Méthode`}</span>
          </Grid>
          <Grid item display="grid">
            <span
              className="hover-opacity"
              onClick={() => {
                scrollToElement("qui-sommes-nous-section");
              }}
              style={{
                cursor: "pointer",
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`Qui sommes-nous ?`}</span>
          </Grid>
        </Grid>
      </Grid>
      {/* Réseaux sociaux */}
      <Grid item display="grid">
        <Grid container direction="column" gap="10px" wrap="nowrap">
          <Grid item display="grid">
            <span
              style={{
                fontSize: myFontSize.medium,
                fontWeight: "bold",
                whiteSpace: "nowrap",
                fontFamily: "Nunito Sans",
              }}
            >{`RÉSEAUX SOCIAUX`}</span>
          </Grid>
          <Grid item display="grid">
            <a
              href="https://www.linkedin.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                cursor: "pointer",
                textDecoration: "none",
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`Linkedin`}</a>
          </Grid>
          <Grid item display="grid">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                cursor: "pointer",
                textDecoration: "none",
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`Facebook`}</a>
          </Grid>
          <Grid item display="grid">
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                cursor: "pointer",
                textDecoration: "none",
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`Instagram`}</a>
          </Grid>
        </Grid>
      </Grid>
      {/* Contact */}
      <Grid item display="grid">
        <Grid container direction="column" gap="10px" wrap="nowrap">
          <Grid item display="grid">
            <span
              style={{
                fontSize: myFontSize.medium,
                fontWeight: "bold",
                whiteSpace: "nowrap",
                fontFamily: "Nunito Sans",
              }}
            >{`CONTACT`}</span>
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`hello@digigab.io`}</span>
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`Paris, France.`}</span>
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                fontSize: myFontSize.medium,
                color: "#031636",
                fontFamily: "Montserrat",
              }}
            >{`Libreville, Gabon.`}</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface StepInfos {
  placeholder?: string;
  type?: "buttonsChoice" | "text" | "longText";
  buttonsChoice?: string[];
  question?: string;
  isOptional?: boolean;
}

interface FormStep {
  step: number;
  question: {
    conditional?: Array<{
      jumpThis?: boolean;
      alwaysDisplay?: boolean;
      ifLastResponseIs?: string;
      displayThis?: StepInfos;
    }>;
  };

  /*jumpToStepIndex?: Array<{
    alwaysJump?: boolean,
    responseIsIn?: string[],
    step: number,
  }>,*/
}

interface Response {
  question?: string;
  value: string;
  step: number;
}

const Form: React.FC<{
  formSteps: FormStep[];
}> = (props) => {
  const screenInfos = useScreenInfos();
  const { formSteps } = props;
  const [responses, setResponses] = React.useState<Response[]>([]);
  const [actualStep, setActualStep] = React.useState<number>(0);
  const [sended, setSended] = React.useState<boolean>(false);
  const [historyStep, setHistoryStep] = React.useState<number[]>([0]);
  const [sendLoading, setSendLoading] = React.useState<boolean>(false);
  const isLastStep = actualStep === formSteps.length - 1;
  const [mandatoryDisplay, setMandatoryDisplay] =
    React.useState<boolean>(false);

  const goPreviousStep = () => {
    if (historyStep?.length > 1) {
      setActualStep(historyStep[historyStep.length - 2]);
      setHistoryStep((x) => {
        const newArr = [...x];
        newArr.splice(-1);
        return newArr;
      });
    }
  };

  const goNextStep = (removeThisStep = false) => {
    if (!removeThisStep) {
      setHistoryStep((x) => [...x, actualStep + 1]);
    } else {
      setHistoryStep((x) => {
        const newArr = [...x];
        newArr.splice(-1);
        newArr.push(actualStep + 1);
        return newArr;
      });
    }
    setActualStep(actualStep + 1);
  };

  const getResponseValueByStep = (index: number): string => {
    const response = responses.find((x) => x.step === index);
    return response?.value ? response.value : "";
  };

  const prenom = getResponseValueByStep(0);
  const nom = getResponseValueByStep(1);
  const nomComplet = !prenom && !nom ? "Anonyme" : [prenom, nom].join(" ");

  const getLastResponse = (): string => {
    if (actualStep === 0) {
      return "";
    }
    const response = responses.find((x) => x.step === actualStep - 1);
    return response?.value ? response.value : "";
  };

  const lastResponse = getLastResponse();

  const getActualResponse = (): string => {
    const response = responses.find((x) => x.step === actualStep);
    return response?.value ? response.value : "";
  };

  const actualResponse = getActualResponse();

  const getStepInfos = (): undefined | StepInfos => {
    if (!formSteps?.[actualStep]?.question?.conditional) {
      return undefined;
    }
    if (
      formSteps[actualStep].question.conditional?.length === 0 ||
      !Array.isArray(formSteps[actualStep].question.conditional)
    ) {
      return undefined;
    }
    const alwaysDisplay = formSteps[actualStep].question.conditional?.find(
      (x) => x.alwaysDisplay === true
    );
    if (alwaysDisplay) {
      return alwaysDisplay?.displayThis;
    }
    const founded = formSteps[actualStep].question.conditional?.find(
      (x) => !x.alwaysDisplay && x.ifLastResponseIs === lastResponse
    );
    if (founded) {
      if (founded.jumpThis === true) {
        goNextStep(true);
      }
      return founded?.displayThis;
    }
    return undefined;
  };

  const stepInfos = getStepInfos();

  const disableButton = !stepInfos?.isOptional && !actualResponse;
  React.useEffect(() => {
    const initResponses: Response[] = [];
    formSteps.forEach((step) => {
      initResponses.push({
        step: step.step,
        value: "",
        question: "",
      });
    });
    setResponses(initResponses);
  }, [formSteps]);

  const executeStartNextOrFinish = isLastStep
    ? () => {
        const responsesFiltered = responses.filter(
          (x) => x.question && x.value
        );
        if (responsesFiltered.length > 0) {
          emailjs.init("keqiW6knjnqcT8n7x");
          emailjs.send("gmail_service", "template_v1xrfxq", {
            from_name: `(${nomComplet})`,
            message: `${responsesFiltered
              .map((x) => x.question + " " + x.value.replaceAll("\n", " "))
              .join("\n")}`,
          });
        }
        setMandatoryDisplay(false);
        setSended(true);
      }
    : () => {
        setMandatoryDisplay(false);
        goNextStep();
      };

  if (sended) {
    return (
      <Grid
        container
        direction="column"
        padding="30px 25px"
        width={screenInfos.isSmallScreen ? "300px" : "400px"}
        minHeight={"500px"}
        justifyContent={"center"}
        gap="40px"
        alignItems="center"
        wrap="nowrap"
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
        }}
      >
        <Grid item display={"grid"}>
          <svg
            width="98"
            height="98"
            viewBox="0 0 98 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.6372 65.2627L37.6372 65.2628C39.8536 67.4789 43.4464 67.479 45.6627 65.2627L70.1626 40.7628C70.1626 40.7627 70.1627 40.7627 70.1627 40.7627C72.379 38.5466 72.3789 34.9533 70.1627 32.7371C67.9464 30.521 64.3536 30.521 62.1373 32.7371L51.1863 43.6878L51.1863 43.6878L41.65 53.2242L35.8629 47.4373C35.8628 47.4373 35.8628 47.4373 35.8628 47.4373C33.6466 45.2211 30.0533 45.2211 27.8371 47.4373C25.621 49.6536 25.621 53.2464 27.8371 55.4627L27.8372 55.4627L37.6372 65.2627ZM96 49C96 74.9572 74.9572 96 49 96C23.0426 96 2 74.9572 2 49C2 23.0426 23.0426 2 49 2C74.9572 2 96 23.0426 96 49Z"
              stroke="#1C274C"
              strokeWidth="4"
            />
          </svg>
        </Grid>
        <Grid item display="grid">
          <span
            style={{
              color: "#1C274C",
              textAlign: "center",
              fontFamily: "Nunito Sans",
              fontSize: myFontSize.medium,
              fontWeight: 700,
            }}
          >
            {`Votre demande a été enregistrée !`}
          </span>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid
      id="container-formulaire"
      container
      direction="column"
      padding="30px 40px"
      minHeight={"400px"}
      width={screenInfos?.windowWidth && screenInfos?.windowWidth < 1200 && screenInfos?.windowWidth > 500 ? "400px" :  "min(max(280px, 30vw), 480px)"}
      justifyContent={"center"}
      gap="40px"
      alignItems="center"
      wrap="nowrap"
      style={{
        backgroundColor: "white",
        borderRadius: "20px",
      }}
    >
      <Grid item display="grid">
        <span
          style={{
            color: "#1C274C",
            textAlign: "center",
            fontFamily: "Nunito Sans",
            fontSize: myFontSize["medium++"],
            fontWeight: 700,
          }}
        >{`Discutons de votre projet`}</span>
      </Grid>
      <Grid item display="grid">
        <Grid container direction="row" wrap="nowrap">
          {formSteps &&
            formSteps.map((formStep, index) => {
              if (actualStep >= formStep.step) {
                return (
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems={"center"}
                  >
                    <Grid item display="grid">
                      <CheckedSVGProgress />
                    </Grid>
                    {formStep.step !== formSteps.length - 1 && (
                      <Grid item display="grid">
                        <BarProgress
                          isFull={true}
                          isMobile={
                            screenInfos.windowWidth &&
                            screenInfos.windowWidth > 1200
                              ? false
                              : true
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              }
              return (
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    <NotCheckedSVGProgress />
                  </Grid>
                  {formStep.step !== formSteps.length - 1 && (
                    <Grid item display="grid">
                      <BarProgress
                        isFull={false}
                        isMobile={
                          screenInfos.windowWidth &&
                          screenInfos.windowWidth > 1200
                            ? false
                            : true
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid item display="grid">
        <span
          style={{
            color: "#1C274C",
            textAlign: "center",
            fontFamily: "Nunito Sans",
            fontSize: myFontSize.medium,
            fontWeight: "700",
          }}
        >
          {stepInfos?.question}
        </span>
      </Grid>
      {stepInfos?.type === "buttonsChoice" && (
        <Grid item display="grid">
          <Grid container direction="row" gap="10px" justifyContent={"center"}>
            {stepInfos.buttonsChoice &&
              stepInfos.buttonsChoice.map((button, index) => {
                return (
                  <Grid item display="grid" key={index}>
                    <RoundedButtonForm
                      text={button}
                      onClick={() => {
                        setResponses((responses) => {
                          const copy = [...responses];
                          const i = copy.findIndex(
                            (c) => c.step === actualStep
                          );
                          copy[i].value = button;
                          copy[i].question = stepInfos?.question
                            ? stepInfos?.question
                            : "";
                          return copy;
                        });
                        goNextStep();
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      )}
      {stepInfos?.type === "longText" && (
        <Grid item display="grid" width="100%">
          <Grid container direction="column" wrap="nowrap" gap="10px">
            <Grid item display="grid">
              <textarea
                onFocus={() => setMandatoryDisplay(false)}
                onBlur={() => setMandatoryDisplay(true)}
                value={actualResponse}
                onChange={(e) => {
                  setResponses((responses) => {
                    const copy = [...responses];
                    const i = copy.findIndex((c) => c.step === actualStep);
                    copy[i].value = e?.target?.value ? e.target.value : "";
                    copy[i].question = stepInfos?.question
                      ? stepInfos?.question
                      : "";
                    return copy;
                  });
                }}
                placeholder={stepInfos?.placeholder}
                maxLength={5000}
                style={{
                  borderRadius: "16px",
                  border:
                    disableButton && mandatoryDisplay
                      ? "1px solid red"
                      : "1px solid #1C274C",
                  fontSize: actualResponse
                    ? myFontSize.medium
                    : myFontSize.small,
                  fontFamily: "Montserrat",
                  color: "#1C274C",
                  padding: "8px 13px",
                  minHeight: "70px",
                  height: "70px",
                }}
              />
            </Grid>
          </Grid>
          {disableButton && mandatoryDisplay && (
            <Grid item display="grid">
              <span
                style={{
                  color: "red",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontSize: myFontSize.small,
                  fontWeight: 400,
                }}
              >
                Ce champ est obligatoire.
              </span>
            </Grid>
          )}
        </Grid>
      )}
      {stepInfos?.type === "text" && (
        <Grid item display="grid" width="100%">
          <Grid container direction="column" wrap="nowrap" gap="10px">
            <Grid item display="grid">
              <input
                onFocus={() => setMandatoryDisplay(false)}
                onBlur={() => setMandatoryDisplay(true)}
                onKeyDown={(event) => {
                  if (disableButton) {
                    setMandatoryDisplay(true);
                    return;
                  }
                  if (event.key === "Enter") {
                    executeStartNextOrFinish();
                  }
                }}
                value={actualResponse}
                onChange={(e) => {
                  setResponses((responses) => {
                    const copy = [...responses];
                    const i = copy.findIndex((c) => c.step === actualStep);
                    copy[i].value = e?.target?.value ? e.target.value : "";
                    copy[i].question = stepInfos?.question
                      ? stepInfos?.question
                      : "";
                    return copy;
                  });
                }}
                placeholder={stepInfos?.placeholder}
                style={{
                  borderRadius: "16px",
                  border:
                    disableButton && mandatoryDisplay
                      ? "1px solid red"
                      : "1px solid #1C274C",
                  fontSize: actualResponse
                    ? myFontSize.medium
                    : myFontSize.small,
                  padding: "5px 10px 5px 10px",
                  minHeight: "25px",
                  height: "25px",
                  fontFamily: "Montserrat",
                  color: "#1C274C",
                  textAlign: "center",
                }}
              />
            </Grid>
            {disableButton && mandatoryDisplay && (
              <Grid item display="grid">
                <span
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: myFontSize.small,
                    fontWeight: 400,
                  }}
                >
                  Ce champ est obligatoire.
                </span>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item display="grid" width={"100%"}>
        <Grid container direction="row" justifyContent={"space-evenly"} gap='15px'>
          {actualStep !== 0 && (
            <Grid item display="grid">
              <GradientBackgroundButton
                text={"Précédent"}
                onClick={() => goPreviousStep()}
              />
            </Grid>
          )}
          {stepInfos?.type !== "buttonsChoice" && (
            <Grid item display="grid">
              <GradientBackgroundButton
                /*
                style={{
                  backgroundImage: disableButton
                    ? "none"
                    : "linear-gradient(to bottom left, #7A4092, #144391, #83CDDD, #7A4092)",
                  backgroundColor: disableButton ? "grey" : undefined,
                  cursor: disableButton ? "not-allowed" : "pointer",
                }}
                */
                text={
                  actualStep === 0
                    ? "Commencer"
                    : isLastStep
                    ? "Envoyer"
                    : "Suivant"
                }
                onClick={
                  disableButton
                    ? () => {
                        setMandatoryDisplay(true);
                      }
                    : () => executeStartNextOrFinish()
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const SectionForm: React.FC = () => {
  const screenInfos = useScreenInfos();
  const isNotTablet = screenInfos?.windowWidth && screenInfos.windowWidth > 1000;
  return (
    <Grid
      container
      justifyContent={"space-evenly"}
      alignItems="center"
      direction={
        screenInfos.windowWidth && screenInfos.windowWidth > 1000
          ? "row"
          : "column-reverse"
      }
      gap="50px"
      padding={screenInfos.isSmallScreen ? "50px 30px" : "50px 50px"}
      style={{
        backgroundImage: `url(${BackgroundHandKeyboard})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >
      <Grid item display="grid">
        <Form
          formSteps={[
            {
              step: 0,
              question: {
                conditional: [
                  {
                    alwaysDisplay: true,
                    displayThis: {
                      type: "text",
                      isOptional: false,
                      placeholder: "Entrez votre prénom ici...",
                      question: "Quel est votre prénom ?",
                    },
                  },
                ],
              },
            },
            {
              step: 1,
              question: {
                conditional: [
                  {
                    alwaysDisplay: true,
                    displayThis: {
                      type: "text",
                      placeholder: "Entrez votre nom ici...",
                      isOptional: false,
                      question: "Quel est votre nom ?",
                    },
                  },
                ],
              },
            },
            {
              step: 2,
              question: {
                conditional: [
                  {
                    alwaysDisplay: true,
                    displayThis: {
                      type: "buttonsChoice",
                      buttonsChoice: ["Oui", "Non"],
                      question:
                        "Êtes-vous une entreprise existante ou en cours de création ?",
                    },
                  },
                ],
              },
            },
            {
              step: 3,
              question: {
                conditional: [
                  {
                    alwaysDisplay: false,
                    ifLastResponseIs: "Oui",
                    displayThis: {
                      type: "text",
                      isOptional: false,
                      placeholder: "Entrez le nom de votre entreprise ici...",
                      question: "Quel est le nom de votre entreprise ?",
                    },
                  },
                  {
                    alwaysDisplay: false,
                    jumpThis: true,
                    ifLastResponseIs: "Non",
                    displayThis: undefined,
                  },
                ],
              },
            },
            {
              step: 4,
              question: {
                conditional: [
                  {
                    alwaysDisplay: true,
                    displayThis: {
                      type: "longText",
                      placeholder: "Entrez une description ici...",
                      isOptional: false,
                      question:
                        "De façon concise, quel est votre projet digital ?",
                    },
                  },
                ],
              },
            },
            {
              step: 5,
              question: {
                conditional: [
                  {
                    alwaysDisplay: true,
                    displayThis: {
                      type: "buttonsChoice",
                      buttonsChoice: [
                        "0 - 2000K Fcfa",
                        "2000K - 3000K Fcfa",
                        "3000K - 5000K Fcfa",
                        "5000K - 10000K Fcfa",
                        "+1000K Fcfa",
                        "Je ne sais pas",
                      ],
                      question:
                        "Avez-vous provisionné un budget pour le développement de ce projet digital ?",
                    },
                  },
                ],
              },
            },
            {
              step: 6,
              question: {
                conditional: [
                  {
                    alwaysDisplay: true,
                    displayThis: {
                      type: "text",
                      placeholder: "Entrez votre adresse email ici...",
                      question: "Quel est votre adresse email ?",
                      isOptional: false,
                    },
                  },
                ],
              },
            },
            {
              step: 7,
              question: {
                conditional: [
                  {
                    alwaysDisplay: true,
                    displayThis: {
                      type: "text",
                      placeholder: "Entrez votre numéro de téléphone ici...",
                      question: "Quel est votre numéro de téléphone ?",
                      isOptional: false,
                    },
                  },
                ],
              },
            },
            {
              step: 8,
              question: {
                conditional: [
                  {
                    alwaysDisplay: true,
                    displayThis: {
                      type: "longText",
                      placeholder: "Entrez une réponse ici...",
                      isOptional: true,
                      question:
                        "Souhaitez-vous nous communiquer des informations complémentaires qui permettraient de mieux appréhender votre projet ? (Facultatif)",
                    },
                  },
                ],
              },
            },
          ]}
        />
      </Grid>
      <Grid item display="grid">
        <Grid container direction="column" wrap="nowrap" gap="20px">
          <Grid item display="grid">
            <span
              style={{
                color: "#FFF",
                textAlign: !isNotTablet ? "center" : "right",
                fontFamily: "Nunito Sans",
                fontSize: myFontSize.large,
                fontWeight: 700,
                maxWidth: "450px",
              }}
            >{`Et si on voyageait dans votre futur digital ?`}</span>
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                color: "#FFF",
                textAlign: !isNotTablet ? "center" : "right",
                fontFamily: "Montserrat",
                fontSize: myFontSize.medium,
                fontWeight: 400,
                maxWidth: "450px",
              }}
            >{`Bénéficiez d'une première évaluation professionnelle en remplissant le formulaire ci-contre. Un expert DigiGab Technologies Solutions vous contactera par la suite pour discuter de votre projet et de la manière dont on peut vous aider à le réaliser.`}</span>
          </Grid>
          {isNotTablet && (
            <Grid item display={"grid"} alignSelf={"center"}>
              <svg
                width={131}
                height={106}
                viewBox="0 0 131 106"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.52499 76.8516C0.158174 78.2184 0.158199 80.4345 1.52505 81.8013L23.7992 104.075C25.166 105.442 27.3821 105.442 28.7489 104.075C30.1157 102.708 30.1157 100.492 28.7489 99.1251L8.94965 79.3264L28.7484 59.5272C30.1152 58.1603 30.1152 55.9442 28.7484 54.5774C27.3815 53.2106 25.1654 53.2106 23.7986 54.5775L1.52499 76.8516ZM123.501 0.324348C123.501 22.1187 122.839 37.534 120.215 48.5613C117.643 59.3716 113.298 65.5237 106.09 69.3605C98.5304 73.385 87.4053 75.1093 70.6057 75.7185C53.877 76.3252 32.2843 75.8261 3.99985 75.8264L3.99994 82.8264C32.0279 82.8261 53.9667 83.3265 70.8594 82.7139C87.6812 82.1039 100.268 80.3903 109.38 75.5395C118.845 70.501 124.132 62.3404 127.025 50.1816C129.867 38.24 130.501 22.0301 130.501 0.324313L123.501 0.324348Z"
                  fill="white"
                />
              </svg>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const App: React.FC = () => {
  useScreenSizeListener();
  const screenInfos = useScreenInfos();

  if (screenInfos.windowWidth === undefined) {
    return (<></>)
  }
  return (
    <Grid container direction="column" wrap="nowrap">
      <Grid item display="grid">
        <HeaderApp />
      </Grid>
      <Grid item display="grid">
        <SectionDescriptionDigiGab />
      </Grid>
      <Grid item display="grid" id="services-section">
        <SectionNosServices />
      </Grid>
      <Grid item display="grid">
        <SectionTexteAvantMethode />
      </Grid>
      <Grid item display="grid" id="methode-section">
        <SectionMethode />
      </Grid>
      <Grid item display="grid" id="form-section">
        <SectionForm />
      </Grid>
      <Grid item display="grid" alignSelf="center">
        <Footer />
      </Grid>
    </Grid>
  );
};

export default App;
