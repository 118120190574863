import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WindowState {
  width: number | undefined;
  height: number | undefined;
}

const initialState: WindowState = {
  width: undefined,
  height: undefined,
};

export const windowSlice = createSlice({
  name: "window",
  initialState,
  reducers: {
    updateScreenSize: (
      state,
      action: PayloadAction<{
        width: number | undefined;
        height: number | undefined;
      }>
    ) => {
      state.height = action.payload.height;
      state.width = action.payload.width;
    },
  },
});

export const windowActions = windowSlice.actions;

export default windowSlice.reducer;
