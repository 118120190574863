import React, { useState } from "react";
import "./Card.scss"; // Assurez-vous d'avoir un fichier CSS pour les styles

const MyFlipCard: React.FC<{
    recto: JSX.Element,
    verso: JSX.Element,
}> = (props) => {
    const [isFlipped, setIsFlipped] = useState(false);
  
    const handleMouseEnter = () => {
      setIsFlipped(true);
    };
  
    const handleMouseLeave = () => {
      setIsFlipped(false);
    };
  
    return (
      <div className="card" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={`card-inner ${isFlipped ? 'flipped' : ''}`}>
          <div className="card-front">
            {props.recto}
          </div>
          <div className="card-back">
            {props.verso}
          </div>
        </div>
      </div>
    );
  };

export default MyFlipCard;
