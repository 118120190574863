import React from "react";
import "./App.css";
import { Grid } from "@mui/material";
import DigigabSmallLogo from "./Assets/digigab_small_logo_white.png";
import DigigabFullLogoColorWhite from "./Assets/digigab_full_logo_white.png";
import { useOutsideClick, useScreenInfos } from "./Redux/hooks";
import { myFontSize, scrollToElement } from "./App";

export const animationBurgerDurationInMs = 500;

export const RoundedButtonForm: React.FC<{
  text: string;
  onClick: () => void;
}> = (props) => {
  return (
    <div
      className="hover-opacity"
      style={{
        padding: "5px 10px 5px 10px",
        borderRadius: 70,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "white",
        width: "fit-content",
        height: "fit-content",
        border: "2px solid #144391",
        cursor: "pointer",
        whiteSpace: "nowrap",
      }}
      onClick={props.onClick}
    >
      <span
        style={{
          fontSize: myFontSize.medium,
          fontWeight: "bold",
          fontFamily: "Montserrat",
          color: "#144391",
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const RoundedButton: React.FC<{
  text: string;
  onClick: () => void;
}> = (props) => {
  return (
    <div
      className="hover-opacity"
      style={{
        padding: "5px 10px 5px 10px",
        borderRadius: 70,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "white",
        border: "2px solid #144391",
        cursor: "pointer",
        whiteSpace: "nowrap",
      }}
      onClick={props.onClick}
    >
      <span
        style={{
          fontSize: myFontSize.medium,
          fontWeight: "bold",
          fontFamily: "Montserrat",
          color: "#144391",
        }}
      >
        {props.text}
      </span>
    </div>
  );
};

export const ButtonNavbar: React.FC<{
  text: string;
  onClick: () => void;
}> = (props) => {
  return (
    <span
      className="hover-opacity"
      onClick={props.onClick}
      style={{
        fontSize: myFontSize["medium+"],
        fontWeight: "bold",
        fontFamily: "Nunito Sans",
        cursor: "pointer",
        color: "white",
        whiteSpace: "nowrap",
      }}
    >
      {props.text}
    </span>
  );
};

export const Navbar: React.FC = () => {
  const [openBurgerMenu, setOpenBurgerMenu] = React.useState<boolean>(false);
  const [animationDisappear, setAnimationDisappear] =
    React.useState<boolean>(false);
  const screenInfos = useScreenInfos();
  const noLogo = screenInfos.isSmallScreen;
  const displayMenuBurger = screenInfos.windowWidth && screenInfos.windowWidth < 900;
  const shortLogo = screenInfos.windowWidth && screenInfos.windowWidth < 850;
  const noRightButton =
    screenInfos.windowWidth && screenInfos.windowWidth < 700;

  const onCloseMenuClick = () => {
    setAnimationDisappear(true);
    setTimeout(() => {
      setOpenBurgerMenu(false);
      setAnimationDisappear(false);
    }, animationBurgerDurationInMs);
  };
  const ref = useOutsideClick(() => onCloseMenuClick());

  if (displayMenuBurger) {
    return (
      <>
        {openBurgerMenu && (
          <div
            ref={ref as any}
            className="animation-container moving-div"
            style={{
              transition: `opacity ${animationBurgerDurationInMs}ms ease-out`,
              opacity: animationDisappear ? 0 : 1,
              zIndex: 1000,
              animation: `slideToLeft ${animationBurgerDurationInMs}ms linear`,
            }}
          >
            <Grid
              container
              direction="column"
              wrap="nowrap"
              gap="30px"
              padding="35px"
            >
              <Grid item display="grid" alignSelf="flex-end">
                <svg
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={onCloseMenuClick}
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30.5458 28.3527C30.6898 28.4967 30.804 28.6676 30.882 28.8558C30.9599 29.0439 31 29.2456 31 29.4492C31 29.6529 30.9599 29.8545 30.882 30.0427C30.804 30.2308 30.6898 30.4018 30.5458 30.5458C30.4018 30.6898 30.2308 30.804 30.0427 30.882C29.8545 30.9599 29.6529 31 29.4492 31C29.2456 31 29.0439 30.9599 28.8558 30.882C28.6676 30.804 28.4967 30.6898 28.3527 30.5458L15.5 17.6912L2.64734 30.5458C2.35651 30.8366 1.96207 31 1.55078 31C1.13948 31 0.745038 30.8366 0.454212 30.5458C0.163385 30.255 8.10753e-09 29.8605 0 29.4492C-8.10753e-09 29.0379 0.163385 28.6435 0.454212 28.3527L13.3088 15.5L0.454212 2.64734C0.163385 2.35651 -3.06436e-09 1.96207 0 1.55078C3.06436e-09 1.13948 0.163385 0.745038 0.454212 0.454212C0.745038 0.163385 1.13948 3.06436e-09 1.55078 0C1.96207 -3.06436e-09 2.35651 0.163385 2.64734 0.454212L15.5 13.3088L28.3527 0.454212C28.6435 0.163385 29.0379 -8.10753e-09 29.4492 0C29.8605 8.10753e-09 30.255 0.163385 30.5458 0.454212C30.8366 0.745038 31 1.13948 31 1.55078C31 1.96207 30.8366 2.35651 30.5458 2.64734L17.6912 15.5L30.5458 28.3527Z"
                    fill="white"
                  />
                </svg>
              </Grid>
              <Grid item display="grid">
                <ButtonNavbar
                  text="Expertise"
                  onClick={() => {
                    scrollToElement("services-section");
                    onCloseMenuClick();
                  }}
                />
              </Grid>
              <Grid item display="grid">
                <ButtonNavbar
                  text="Méthode"
                  onClick={() => {
                    scrollToElement("methode-section");
                    onCloseMenuClick();
                  }}
                />
              </Grid>
              <Grid item display="grid">
                <ButtonNavbar
                  text="Qui sommes-nous ?"
                  onClick={() => {
                    scrollToElement("qui-sommes-nous-section");
                    onCloseMenuClick();
                  }}
                />
              </Grid>
              <Grid item display="grid" alignSelf={'flex-start'}>
                <RoundedButton
                  text="Discutons de votre projet"
                  onClick={() => {
                    scrollToElement("container-formulaire");
                    onCloseMenuClick();
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}
        <Grid
          padding="30px"
          alignItems={"center"}
          container
          direction="row"
          gap="20px"
          justifyContent={"space-between"}
        >
          <Grid item display="grid">
            <img
              src={DigigabSmallLogo}
              alt="Logo"
              style={{
                width: "40px",
                transition: `opacity ${animationBurgerDurationInMs}ms ease-out`,
                opacity: openBurgerMenu ? 0 : 1,
              }}
            />
          </Grid>
          <Grid item display="grid">
            <svg
              onClick={() => {
                setOpenBurgerMenu(true);
              }}
              className="hover-opacity"
              style={{
                cursor: openBurgerMenu ? "default" : "pointer",
                transition: `opacity ${animationBurgerDurationInMs}ms ease-out`,
                opacity: openBurgerMenu ? 0 : 1,
              }}
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 36V32H42V36H6ZM6 26V22H42V26H6ZM6 16V12H42V16H6Z"
                fill="white"
              />
            </svg>
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      padding="30px"
      gap="30px"
      justifyContent={"space-evenly"}
      alignItems={"center"}
    >
      {!noLogo && (
        <Grid item display="grid">
          {shortLogo ? (
            <img
              src={DigigabSmallLogo}
              alt="Logo"
              style={{
                width: "40px",
              }}
            />
          ) : (
            <img
              src={DigigabFullLogoColorWhite}
              alt="Logo"
              style={{
                width: "120px",
              }}
            />
          )}
        </Grid>
      )}
      <Grid item display="grid">
        <Grid container direction="row" wrap="nowrap" gap="20px">
          <Grid item display="grid">
            <ButtonNavbar
              text="Expertise"
              onClick={() => {
                scrollToElement("services-section");
              }}
            />
          </Grid>
          <Grid item display="grid">
            <ButtonNavbar
              text="Méthode"
              onClick={() => {
                scrollToElement("methode-section");
              }}
            />
          </Grid>
          <Grid item display="grid">
            <ButtonNavbar
              text="Qui sommes-nous ?"
              onClick={() => {
                scrollToElement("qui-sommes-nous-section");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {!noRightButton && (
        <Grid item display="grid">
          <RoundedButton
            text="Discutons de votre projet"
            onClick={() => {
              scrollToElement("container-formulaire");
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
