import { configureStore } from '@reduxjs/toolkit'
import windowSlice from './windowSlice'

const store = configureStore({
  reducer: {
    window: windowSlice,
  },
})

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch